import React from "react"
import { Link } from "gatsby"

import styles from "./flow-top-nav.module.css"
import sharedStyles from "../../shared/shared.module.css"

const PhoneValidationTopNav = () => (
  <div className={styles.topNav}>
    <div className={styles.topNavContent}>
      <h3 className={styles.topNavTitle}>Phone Validation</h3>
      <Link to={'/products/phone-validation/summary'} className={sharedStyles.tigerfaceLinkLight}>
        Summary
      </Link>
      <Link to={'/products/phone-validation/features'} className={sharedStyles.tigerfaceLinkLight}>
        Features <span className={sharedStyles.hiddenMobile}>& Pricing</span>
      </Link>
      <Link to={'/products/phone-validation/user-guide'} className={sharedStyles.tigerfaceLinkLight}>
      <span className={sharedStyles.hiddenMobile}>User </span>Guide
      </Link>
      {/* <Link to={'/products/phone-validation/faq'} className={sharedStyles.tigerfaceLinkLight}>
        FAQ
      </Link> */}
    </div>
  </div>
);

export default PhoneValidationTopNav;