import React from "react"
import { graphql } from "gatsby"

// Components
import Layout from "../components/layout"
import Band from "../components/band"
import SEO from "../components/seo"
import PhoneValidationTopNav from "../components/top-nav/phone-validation-top-nav"

// Styles
import grid from "../shared/bootstrap-grid.module.css"
import sharedStyles from "../shared/shared.module.css"

export default function PhoneValidationGuideTemplate({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} keywords={[`tigerface`, `systems`, `salesforce`, `products`, `phone validation`, `${frontmatter.title}`]} />

      <PhoneValidationTopNav />

      <Band>

        <h1>{frontmatter.title}</h1>

        {frontmatter.videoUrl.length > 0 &&
          <iframe src={frontmatter.videoUrl} className={sharedStyles.ytVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        }

        <div
          className={`${grid.mtLg0} ${grid.mt4}`}
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <a href="#top" style={{ display: 'block', textAlign: 'center', marginTop: '44px' }}>Back To Top</a>
      </Band>
    </Layout>
  )
}

export const pageQuery = graphql`
query($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path } }) {
    html
    frontmatter {
      path
      title
      videoUrl
    }
  }
}`
